<template>
  <div>
    <v-data-table
      class="inventory-details"
      :headers="headers"
      :items="filteredEventList"
      item-key="name"
    >
      <template v-slot:header.inventoryId="{}">
        <v-autocomplete
          label="Product"
          placeholder="Filter by product"
          hide-details
          dense
          clearable
          @click.prevent.stop=""
          v-model="productFilter"
          :items="products"
          @change="filter"
        />
      </template>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="(e, index) in items" :key="index">
            <td>
              <a
                target="_blank"
                :href="`https://prod.${domain}.com/admin/inventory/${e.inventoryId}`"
              >
                {{ e.product }}
              </a>
            </td>
            <td>{{ e.time | formatDateTime }}</td>
            <td>
              <a
                target="_blank"
                :href="`https://prod.${domain}.com/admin/adjustment/${e.adjustmentId}`"
                >{{ e.quantity | formatNumber }}</a
              >
            </td>
            <td>
              <div v-if="e.costAdjustment != 0">
                <v-menu bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <div v-bind="attrs" v-on="on">
                      <span class="red--text">{{ e.value | currency }}</span>
                    </div>
                  </template>
                  <v-list>
                    <v-layout column style="height: 60vh">
                      <v-flex style="overflow: auto">
                        <v-simple-table class="ma-4">
                          <thead>
                            <tr>
                              <th colspan="2" class="text-center">
                                Cost Adjustments
                              </th>
                            </tr>
                            <tr>
                              <th>Amount</th>
                              <th>Notes</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(adj, index) in getCostAdjustments(
                                e.inventoryId,
                                date
                              )"
                              :key="index"
                            >
                              <td>{{ adj.amount | currency }}</td>
                              <td>
                                <div class="ma-2">{{ adj.notes }}</div>
                                <div class="text-caption text-right ma-2">
                                  {{ adj.adjustmentTime | formatDateYear }}
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </v-simple-table>
                      </v-flex></v-layout
                    >
                  </v-list>
                </v-menu>
              </div>
              <div v-else>
                {{ e.value | currency }}
              </div>
            </td>
            <td>
              {{ e.unitCost | currency }}
            </td>
          </tr>
        </tbody>
        <tfoot v-if="summary">
          <tr>
            <td colspan="2">Total</td>
            <td>
              {{ summary.quantity | formatNumber }}
            </td>
            <td>
              {{ summary.value | currency }}
            </td>
            <td>
              <span v-if="summary.quantity && summary.quantity != 0">
              {{ summary.value / summary.quantity | currency }}
              </span>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    eventList: {
      type: Array,
      required: true,
      default: () => [],
    },
    eventType: {
      type: String,
      required: false,
      default: "",
    },
    domain: {
      type: String,
      required: false,
      default: "localhost",
    },
    date: {
      type: Date,
    },
  },
  computed: {
    ...mapGetters("inventoryCostAdj", ["costAdjustmentService"]),
  },
  data: function () {
    return {
      headers: [
        { text: "Inventory ID", value: "inventoryId" },
        { text: "Time", value: "time" },
        { text: "Quantity", value: "quantity" },
        { text: "Cost", value: "value" },
        { text: "Unit Cost", value: "unitCost" },
      ],
      summary: null,
      filteredEventList: [],
      productFilter: null,
      products: [],
    };
  },
  mounted() {
    this.filteredEventList = this.eventList;
    this.summarize();
  },
  methods: {
    getCostAdjustments(inventoryId, balanceDate) {
      return this.costAdjustmentService.getCostAdjustmentDetails(
        inventoryId,
        balanceDate
      );
    },
    filter() {
      if (this.productFilter && this.productFilter.trim() !== "") {
        this.filteredEventList = this.filteredEventList.filter((e) => e.product == this.productFilter );
      } else {
        this.filteredEventList = this.eventList;
      }

      this.summarize();
    },
    summarize() {
      const products = new Set();
      this.summary = this.filteredEventList.reduce((group, row) => {
        products.add(row.product);

        if (group) {
          group.value += row.value;
          group.quantity += row.quantity;
        } else {
          group = {
            value: row.value,
            quantity: row.quantity,
          };
        }

        return group;
      }, null);

      this.products = [...products]
    },
  },
};
</script>

<style scoped>
.inventory-details thead.v-data-table-header > tr > th > div {
  display: inline-block;
  width: 80%;
}
</style>
